import * as dompack from 'dompack';
import './mobilemenu.scss';

export default class MobileMenu {
  constructor() {
    this.elMobileMenu = dompack.qS('#toggle-mobile-menu');

    for (const elToggleMenu of dompack.qSA('.js-ww-toggle-mobile-menu')) {
      elToggleMenu.addEventListener('click', evt => {
        dompack.stop(evt);
        this.toggle();
      });
    }

    for (const elToggleMenu of dompack.qSA('.js-ww-open-mobile-menu')) {
      elToggleMenu.addEventListener('click', evt => {
        dompack.stop(evt);
        this.show();
      });
    }

    for (const elToggleMenu of dompack.qSA('.js-ww-close-mobile-menu')) {
      elToggleMenu.addEventListener('click', evt => {
        dompack.stop(evt);
        this.hide();
      });
    }

    for (const elLinkWithSubItems of dompack.qSA('.js-ww-mobilemenu-toggle-submenu')) {
      elLinkWithSubItems.addEventListener('click', evt => {
        dompack.stop(evt);
        elLinkWithSubItems.classList.toggle('ww-mobilemenu__link--active');
      });
    }
  }

  toggle() {
    if (this.elMobileMenu.classList.contains('is-active'))
      this.hide();
    else
      this.show();
  }

  show() {
    document.documentElement.classList.add('ww-mobile-menu-open');
    // this.elMobileMenu.classList.add('is-active');
  }

  hide() {
    document.documentElement.classList.remove('ww-mobile-menu-open');
    // this.elMobileMenu.classList.remove('is-active');
  }
}
