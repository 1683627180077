/* global $, Swiper */

import './textimageslider.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('.w-textimageslider').each(function(){
    let item = $(this);
    item.data('position', 0);

    let projectsLength = $(this).find('.w-textimageslider__col__content--text').length - 1;
    if (projectsLength <= 0) {
      item.find('.w-textimageslider__col__content__nav--panel').css("display", "none");
    }

    $(item.find('.swiper-container')).each(function(){
      let item = $(this);
      let numSlides = item.find('.swiper-slide').length - 1;

      //Slider for images of the projects
      let swiper = new Swiper (this, {
        autoplay: 4000,
        loop: numSlides > 0,
        allowSwipeToPrev: numSlides > 0,
        allowSwipeToNext: numSlides > 0,
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      if(numSlides <= 0){
        $(item.find('.swiper-button-prev')).css("display", "none");
        $(item.find('.swiper-button-next')).css("display", "none");
      }
    });
  });

  //Sets event listeners
  $('.w-textimageslider').on('click', '.panel--left', function(e){scrollProjects('left', e)});
  $('.w-textimageslider').on('click', '.panel--right', function(e){scrollProjects('right', e)});

  //Slider for the projects
  function scrollProjects(direction, event) {
    let isAnimating = false;
    if(!isAnimating){
      let item = $($(event.target).closest('.w-textimageslider'));
      let projectsLength = item.find('.w-textimageslider__col__content--text').length - 1;

      isAnimating = true;
      if (direction === 'right' && item.data('position') < projectsLength) {
        item.data('position', (item.data('position') + 1));
      } else if (direction === 'left' && item.data('position') > 0) {
        item.data('position', (item.data('position') - 1));
      } else if (item.data('position') == projectsLength) {
        item.data('position', 0);
      } else if (item.data('position') == 0) {
        item.data('position', projectsLength);
      }

      let leftPos = (item.data('position') * $(item.find('.w-textimageslider__col__content')).width());
      $(item.find('.w-textimageslider__col__content__container')).animate({
        scrollLeft: leftPos
      }, "slow", function() {
        isAnimating = false;
      });

      leftPos = (item.data('position') * $(item.find('.w-textimageslider__col__content__nav--title')).width());
      $(item.find('.w-textimageslider__col__content__nav__container')).animate({
        scrollLeft: leftPos
      }, "slow");
    }
  }

  //Responsiveness of the projects slider
  $(window).resize(function() {
    $('.w-textimageslider').each(function(){
      let item = $(this);
      let position = item.data('position');

      $(item.find('.w-textimageslider__col__content__container').scrollLeft((position * $(item.find('.w-textimageslider__col__content')).width())));
      $(item.find('.w-textimageslider__col__content__nav__container').scrollLeft((position * $(item.find('.w-textimageslider__col__content__nav--title')).width())));
    });
  });
});
