import * as dompack from 'dompack';
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';

// Enable forms and our builtin validation
forms.setup({validate:true});

// Replaces upload fields with a nicer and edit-supporting version
dompack.register('.wh-form__upload', node => new UploadField(node));

// Enable the imgedit and/or rtd fields
dompack.register('.wh-form__imgedit', node => new ImgEditField(node));
dompack.register('.wh-form__rtd', node => new RTDField(node));

dompack.onDomReady(() => {
  // for (const button of dompack.qSA('.wh-form__button')) {
  //   button.classList.add('btn');
  //   button.classList.add('btn-primary');
  // }
});
