/* global $, Swiper */
import './verticaltabs.scss';

import * as dompack from 'dompack';

import * as modal from '@mod-ww/components/modal';

const carouselOptions = {
  nextButton: '.swiper-button-next',
  prevButton: '.swiper-button-prev',
  effect: 'fade',
  fade: {
    crossFade: true,
  },
  noSwipingClass: 'swiper-slide',
};

const swipers = new Map();
let verticalTabs;

dompack.onDomReady(() => {
  verticalTabs = $('.w-verticaltabs');

  verticalTabs.each(function () {
    const thisEl = $(this);
    // Small trick to add min height to the swiper items so the bullets can render all
    const minHeight = thisEl.find('.w-verticaltabs__bullets').height();
    thisEl.find('.swiper-wrapper').css('min-height', minHeight);
  });

  activateTabs();

  window.onresize = activateTabs;
});

function activateTabs() {
  verticalTabs.each(function (vIndex) {
    const thisEl = $(this);

    const isMobile = window.innerWidth <= 768;

    const bullets = thisEl.find('.w-verticaltabs__bullet');

    // Unbind previous event listeners for all bullets/items
    bullets.unbind();

    if (isMobile) {
      bullets.click(function (evt) {
        evt.preventDefault();
        const thisEl = $(this);

        if (!thisEl.is('[data-modal]')) {
          return;
        }

        const modalId = thisEl.data('modal-id');

        // get parent container
        const $container = thisEl.closest('.w-verticaltabs');

        // find the modal contents
        const $contents = $container
          .find(`.contentmodal[data-modal-id="${modalId}"]`)
          .get(0);

        modal.runModal($contents);
      });
    } else {
      const $swiperContainer = thisEl.find('.swiper-container').get(0);
      const carouselSwiper =
        swipers.get(vIndex) || new Swiper($swiperContainer, carouselOptions);

      swipers.set(vIndex, carouselSwiper);

      bullets.each(function (bIndex, el) {
        $(el).click(function (evt) {
          evt.preventDefault();
          bullets.removeClass('selected').eq(bIndex).addClass('selected');
          carouselSwiper.slideTo(bIndex);
        });
      });
    }
  });
}
