import './reviews.scss';

import RatingField from '../../forms/rating/rating.es';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!dompack.qS('.page-reviews')) return;

  dompack.register('.rating', (node) => new RatingField(node));
});
