import * as dompack from 'dompack';

import './rating.scss';

export default class RatingField {
  constructor(node, options) {
    this.stars = node.querySelectorAll('.star-icon');
    this.input = node.querySelector('.rating__input');

    this.stars.forEach((el) => {
      el.onclick = () => {
        const rating = parseInt(el.dataset.rating);
        this.input.value = rating * 2;
        for (let i = 1; i <= 5; i++) {
          const tmp = dompack.qS(`.star-icon[data-rating="${i}"]`);
          if (i > rating) {
            tmp.classList.remove('on');
            tmp.classList.add('off');
          } else {
            tmp.classList.remove('off');
            tmp.classList.add('on');
          }
        }
      };
    });
  }
}
