/* global bootstrap */
import './modal.scss';

import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';

function htmlToElement(html) {
  html = html.trim();
  let template = document.createElement('template');
  template.innerHTML = html;
  return template.content.firstChild;
}

// add class "js-ww-dialog-close" to element to close the modal
export async function runModal(contents, options) {
  // FIXME: use Bootstrap if available (like runMessageBox)

  options = { allowcancel: true,
              beforeOpen: null,
              type: '', // '', 'youtube'
              video: null, // { id: '', autoplay: true, rel: '' ('0' or '1'), enablejsapi: false }
              extraClassNames: [],
              // borrow: '',
              clone: true, // only used if param `contents` is a DOM element
              ...options,
            };

  // create dialog
  let dialog = dialogapi.createDialog(options);

  // add contents
  if (options.video && options.video.link) {
    const modalHTML =
      `<video id="subscribe-video-start-car" playsinline controls autoplay>
         <source src="${options.video.link}" type="video/mp4" />
       </video>`;
    dialog.contentnode.appendChild(htmlToElement(modalHTML));
    options.extraClassNames = options.extraClassNames.concat(['ww-dialog--video']);
  } else if (['youtube', 'vimeo'].includes(options.type)) {
    let url = new URL(options.type === 'youtube' ? `https://www.youtube.com/embed/${options.video.id}`
                                                 : `https://player.vimeo.com/video/${options.video.id}`);

    if (options.video.autoplay ?? true) // autoplay defaults to true
      url.searchParams.set('autoplay', 1);

    if (options.video.rel)
      url.searchParams.set('rel', options.video.rel);

    if (!!options.video.enablejsapi)
      url.searchParams.set('enablejsapi', '1');

    const modalHTML = `<iframe width="100%" height="100%" src="${url.toString()}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    dialog.contentnode.appendChild(htmlToElement(modalHTML));
    options.extraClassNames = options.extraClassNames.concat(['ww-dialog--video',`ww-dialog--${options.type}video`]);
  }
  else if (typeof(contents) === 'object' && contents.nodeType) { // assuming a DOM object
    if (options.clone) {
      dialog.contentnode.appendChild(contents.cloneNode(true));
    } else {
      dialog.contentnode.appendChild(contents);
    }
  }
  else if (typeof(contents) === 'string') { // assuming HTML, convert to a DOM element
    dialog.contentnode.appendChild(htmlToElement(contents));
  }
  else {
    throw `unsupported type "${typeof(contents)}"`;
  }

  for (const className of options.extraClassNames) {
    dialog.contentnode.classList.add(className);
  }

  if (options.allowcancel) {
    let button = dompack.create('a', { className: 'ww-dialog__closebutton',
                                       on: { click: event => dialog.resolve() },
                                     });
    dialog.contentnode.appendChild(button);
  }

  for (const closeEl of dialog.contentnode.querySelectorAll('.js-ww-dialog-close')) {
    closeEl.addEventListener('click', evt => {
      dompack.stop(evt);
      dialog.resolve();
    });
  }

  if (options.beforeOpen) {
    options.beforeOpen(dialog);
  }

  // show dialog
  await dialog.runModal();

  // const $formContainer = $(dialog.options.borrow);
  // const $form = $formContainer.find('form.wh-form');
  // $form.addClass('wh-form--allowsubmit');
  // $form.find('.wh-form__page').eq(0).removeClass('wh-form__page--hidden').addClass('wh-form__page--visible');
  // $form.find('.wh-form__page').eq(1).removeClass('wh-form__page--visible').addClass('wh-form__page--hidden');
}

// returns 'cancel' or 'submit'
export async function runMessageBox(title, question, choices, options) {
  if (typeof(bootstrap) !== 'object') {
    throw 'no Bootstrap available'; // do we need fallback? return dialogapi.runMessageBox(question, choices, options);
  }

  let buttonsHtml = '';
  for (const choice of choices) {
    if (!['cancel','submit'].includes(choice.type)) {
      throw `invalid choice type "${choice.type}"`;
    }
    buttonsHtml += `<button type="button" data-type="${choice.type}" class="btn ${choice.class}" ${choice.type === 'cancel' ? 'data-bs-dismiss="modal"' : ''}>${choice.title}</button>`;
  }
  const modal = `
<div class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
      </div>
      <div class="modal-body">
        <p>${question}</p>
      </div>
      <div class="modal-footer">
        ${buttonsHtml}
      </div>
    </div>
  </div>
</div>`;
  const modalEl = document.body.appendChild(htmlToElement(modal));

  let result = 'cancel';
  let myModal;
  await new Promise(function(resolve, reject) {
    myModal = new bootstrap.Modal(modalEl, { backdrop: 'static' });
    myModal.show();

    modalEl.addEventListener('hidden.bs.modal', evt => dompack.remove(modalEl));

    modalEl.querySelector('*[data-type="submit"]').addEventListener('click', evt => resolve('submit'));
    modalEl.querySelector('*[data-type="cancel"]').addEventListener('click', evt => reject('cancel'));
  }).then(function(val) {
    result = val;
  }).catch(function(err) {
    result = err;
  });

  myModal.hide();

  return result;
}
