import './typewriter.scss';
import * as dompack from 'dompack';
import Typed from 'typed.js';

dompack.onDomReady(() => {
  const typewriters = dompack.qSA('.w-typewriter');

  for (const tw of typewriters) {
    try {
      new Typed(tw, {
        backDelay: Number(tw.dataset.backDelay),
        typeSpeed: Number(tw.dataset.typeSpeed),
        backSpeed: Number(tw.dataset.backSpeed),
        loop: true,
        strings: JSON.parse(tw.dataset.strings),
        onBegin: function (self) {
          // Because we dont want to compromise SEO, the first word of the array is inside the content of the typewriter by default
          // but since it stores the text that was initially inside,
          // it will replace the content after backspacing the last word with the initial text,
          // causing the first word to appear instantly
          // this prevents that and starts typing the first word instead of backspacing it
          if (self.curLoop > 0) {
            self.currentElContent = null;
            self.onBegin = (self) => {}; // remove callback
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
});
