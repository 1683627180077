import "./reviews.scss";

import * as dompack from "dompack";
import getTid from "@mod-tollium/js/gettid";

let allShown, reviews, currentLang, toggleBtn;

dompack.onDomReady(() => {
  if (!dompack.qS(".w-reviews__container")) return;

  allShown = false;
  reviews = dompack.qSA(".w-reviews__review");
  currentLang = document.querySelector("html").getAttribute("lang");

  toggleBtn = dompack.qS("#btn-reviews-toggler");
  if (toggleBtn) {
    toggleBtn.onclick = toggleLanguages;
    toggleLanguages();
  }

  setupExpandableReviews();
});

function toggleLanguages(evt) {
  if (evt) dompack.stop(evt);

  allShown = !allShown;

  const newText = allShown
    ? toggleBtn.dataset.hideText
    : toggleBtn.dataset.showText;

  toggleBtn.textContent = newText;

  reviews.forEach((review) => {
    const showReview = allShown || currentLang === review.dataset.language;

    dompack.toggleClass(review, "hidden", !showReview);
  });
}

function setupExpandableReviews() {
  let buttons = dompack.qSA(".w-reviews__readmore");

  for (let button in buttons) {
    let parent = buttons[button].closest(".w-reviews__review");
    let content = parent.querySelector(".w-reviews__content");
    if (!(content.scrollHeight > content.clientHeight)) {
      buttons[button].dataset.show = "false";
      continue;
    }

    buttons[button].addEventListener("click", function (evt) {
      content.classList.toggle("expand");

      if (content.classList.contains("expand")) {
        buttons[button].textContent = getTid("nubiumreviews:js.showless");
      } else {
        buttons[button].textContent = getTid("nubiumreviews:js.showmore");
        content.scrollIntoView(true);
      }
    });
  }
}
