import './ctas.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  const ctas = dompack.qSA('.w-ctas');

  for (const cta of ctas) {
    //add onclick listener to popup cta's
    const popupListener = cta.querySelectorAll('.w-ctas__popup--listener');
    popupListener.forEach((e) => {
      e.addEventListener('click', () => {
        popupHandler(e);
      });
    });

    //add onclick toggle on mobile to hover cta's
    const hoverToggle = cta.querySelectorAll('.w-ctas__hover--container');
    hoverToggle.forEach((e) => {
      e.addEventListener('click', () => {
        e.classList.toggle('active');
      });
    });
  }
});

function popupHandler(el) {
  //create popup elements
  const popupBackdrop = document.createElement('div');
  const popup = document.createElement('div');
  const content = el.firstChild.cloneNode(true);
  const backButtonContainer = document.createElement('div');
  const backButton = document.createElement('div');

  //insert popupelements in body and assign classes
  backButtonContainer.appendChild(backButton);
  backButton.setAttribute('class', 'w-ctas__popup--item');
  backButton.innerHTML =
    '<svg id="popup-backbutton" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>window-close</title><path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" /></svg>';
  backButton.addEventListener('click', () => {
    closePopupHandler();
  });

  popup.appendChild(backButtonContainer);
  backButtonContainer.setAttribute(
    'class',
    'w-ctas__popup--btncontainer w-ctas__popup--item'
  );

  popup.appendChild(content);
  content.setAttribute('class', 'w-ctas__popup--content');
  content.setAttribute('style', 'display: block;');

  popupBackdrop.appendChild(popup);
  popup.setAttribute('class', 'w-ctas__popup w-ctas__popup--item');
  popup.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  document.body.appendChild(popupBackdrop);
  popupBackdrop.setAttribute(
    'class',
    'w-ctas__popup--backdrop w-ctas__popup--item'
  );
  popupBackdrop.addEventListener('click', () => {
    closePopupHandler();
  });
}

function closePopupHandler() {
  //delete popupelements
  const popup = document.getElementsByClassName('w-ctas__popup--item');

  for (let i = popup.length - 1; i >= 0; i--) {
    popup[i].remove();
  }
}
