import './logoslider.scss';

import * as dompack from 'dompack';

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import '@splidejs/splide/css';

Splide.defaults = {
  type: 'loop',
  drag: 'free',
  focus: 'center',
  perPage: 5,
  perMove: 1,
  autoScroll: {
    speed: 1,
    rewind: true,
  },
  gap: '1rem',
  breakpoints: {
    768: {
      perPage: 3,
    },
    480: {
      perPage: 2,
    },
  },
};

dompack.onDomReady(() => {
  dompack.qSA('.splide').forEach((el) => {
    new Splide(el).mount({ AutoScroll });
  });
});
