/* global $ */
import './ctaslider.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  const sliders = dompack.qSA('.w-ctaslider');

  for (const slider of sliders) {
    const actions = slider.querySelectorAll('.w-ctaslider__action');

    actions.forEach((a) =>
      a.addEventListener('click', (e) => onActionClick(e, slider))
    );

    //add onclick listener to popup cta's
    const popupListener = slider.querySelectorAll('.w-ctaslider__popup--listener');
    popupListener.forEach((el) => 
      el.addEventListener('click', () => {popupHandler(el)})
    );

    //add onclick toggle on mobile to hover cta's
    const hoverToggle = slider.querySelectorAll('.w-ctaslider__hover--container');
    hoverToggle.forEach((el) => 
      el.addEventListener('touchstart', () => {toggleHandler(el)})
    );
  }
});

function onActionClick(e, slider) {
  e.preventDefault();

  const availableWidth = dompack.qS('.ww-grid').offsetWidth;
  const colWidth = slider.querySelector('.w-ctaslider__col').offsetWidth;
  const fitCount = Math.floor(availableWidth / colWidth);

  // console.log(availableWidth, colWidth, fitCount);

  const shiftBy = parseInt(e.target.dataset.shiftAmount);
  const currentShift = parseInt(
    getComputedStyle(slider).getPropertyValue('--shift-count')
  );
  const max = slider.querySelectorAll('.w-ctaslider__col').length - fitCount;

  if (max <= 0) return;

  if (currentShift >= max && shiftBy > 0) {
    slider.style.setProperty('--shift-count', 0);
  } else if (currentShift === 0 && shiftBy < 0) {
    slider.style.setProperty('--shift-count', max);
  } else {
    slider.style.setProperty('--shift-count', shiftBy + currentShift);
  }
}

function popupHandler (el) {
  //create popup elements
  const popupBackdrop = document.createElement('div');
  const popup = document.createElement('div');
  const content = el.firstChild.cloneNode(true);
  const backButtonContainer = document.createElement('div');
  const backButton = document.createElement('div');

  //insert popupelements in body and assign classes
  backButtonContainer.appendChild(backButton);
  backButton.setAttribute("class", "w-ctaslider__popup--item");
  backButton.innerHTML = '<svg id="popup-backbutton" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>window-close</title><path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" /></svg>';
  backButton.addEventListener('click', () => {closePopupHandler()});

  popup.appendChild(backButtonContainer)
  backButtonContainer.setAttribute("class", "w-ctaslider__popup--btncontainer w-ctaslider__popup--item")

  popup.appendChild(content);
  content.setAttribute("class", "w-ctaslider__popup--content")
  content.setAttribute("style", "display: block;")

  popupBackdrop.appendChild(popup);
  popup.setAttribute("class", "w-ctaslider__popup w-ctaslider__popup--item");
  popup.addEventListener('click', (e) => {e.stopPropagation();})

  document.body.appendChild(popupBackdrop)
  popupBackdrop.setAttribute("class", "w-ctaslider__popup--backdrop w-ctaslider__popup--item");
  popupBackdrop.addEventListener('click', () => {closePopupHandler()});
}

function closePopupHandler () {
  //delete popupelements
  const popup = document.getElementsByClassName("w-ctaslider__popup--item");

  for (let i = popup.length - 1; i >= 0; i--) {
    popup[i].remove();
  }
}

function toggleHandler (el) {
  const content = el.querySelector('.w-ctaslider__hover--content');

  if (content.style.maxHeight != '300px') {
    content.style.maxHeight = '300px';
  } else {
    content.style.maxHeight = '0px';
  }
}
